<template>
  <div class="formulario-promocoes">
    <form @submit.prevent class="formulario-container form-planilha">
      <div class="formulario-item media select-cargo">
        <label class="formulario-item-label">Segmentação</label>
        <multiselect label="descricao" track-by="id" v-model="idCargo" :options="cargos" :searchable="false"
          :allow-empty="false" select-label="Selecionar" deselect-label="Remover" placeholder="Clique para selecionar o cargo"
          selected-label="Selecionado" />
      </div>

      <div class="formulario-linha linha-tripla media">
        <div class="formulario-item container-upload planilha-flex">
          <label class="formulario-item-label text-center">{{ nomePlanilha }}</label>
          <input id="input-planilha" type="file" accept=".xls,.xlsx"
            @change="atualizarPlanilha('planilha', 'dataPlanilha', $event)" />
          <div class="planilha-flex">
            <label for="input-planilha" class="planilha-btn btn-icon"><i class="material-icons">send</i>Adicionar</label>
            <transition name="fade">
              <a v-if="dataPlanilha" class="remove-image"
                @click="removerPlanilha('planilha', 'dataPlanilha', 'input-planilha')">Remover</a>
            </transition>
          </div>
        </div>
        <div class="planilha-flex">
          <label class="formulario-item-label text-center">Baixar Modelo Apuração</label>
          <a href="/documentos/RANKING.xlsx" download style="text-decoration:none; width: 140px">
            <div class="planilha-btn btn-icon"><i class="material-icons">cloud_download</i>Baixar</div>
          </a>
        </div>

      </div>
      <div class="container-btns margin-top">
        <button class="btn btn-cancelar btn-padding" @click.prevent="$router.push('/ranking/listar')">Voltar</button>
        <button class="btn btn-laranja btn-padding" @click="chamaConfirmacao" :disabled="$v.$invalid">Salvar</button>
      </div>
    </form>
    <div v-if="erros" class="tabela-informacoes" key="Lista">
      <!-- v-if="treinamentos.length > 0" -->
      <div class="tabela-linha tabela-linha-titulo">
        <div class="tabela-linha-nome">
          <p>ABA</p>
        </div>
        <div class="tabela-linha-nome">
          <p>ERRO</p>
        </div>
        <div class="tabela-linha-nome">
          <p>LINHA</p>
        </div>
      </div>
      <div v-for="(item, index) in tipoErros" :key="index" class="tabela-linha tabela-linha-informacoes">
        <div class="tabela-linha-nome">
          <p class="msg-erro-envio-planilha">{{ item.aba }}</p>
        </div>
        <div class="tabela-linha-nome">
          <p class="msg-erro-envio-planilha">{{ item.descricao }}</p>
        </div>
        <div class="tabela-linha-nome">
          <p class="msg-erro-envio-planilha">{{ item.linha }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { listarClientes } from "@/services/clientes";
import { required } from "vuelidate/lib/validators";
import { enviarPlanilhaRanking } from "@/services/apuracao-ranking";
import Multiselect from "vue-multiselect";
import { listarCargosAgrupados } from "@/services/espelho";

export default {
  name: "FormularioRanking",

  components: { Multiselect },

  data() {
    return {
      nomePlanilha: "Envie o arquivo de apuração",
      dataPlanilha: null,
      planilha: null,
      erros: false,
      tipoErros: [],
      cargos: [],
      idCargo: [],
    };
  },
  validations: {
    dataPlanilha: {
      required
    }
  },
  methods: {
    removerPlanilha(form, planilha, elem) {
      this[planilha] = null;
      this.nomePlanilha = "Envie o arquivo de apuração"
      document.querySelector(`#${elem}`).value = null;
    },

    atualizarPlanilha(form, planilha, $event) {
      if (!$event.target.files[0]) {
        this[planilha] = null;
        this.nomePlanilha = "Envie o arquivo de apuração"
        return false;
      }
      this.planilha = $event.target.files[0];
      const reader = new FileReader();
      reader.onload = (bg) => {
        this[planilha] = bg.target.result;
      };
      reader.readAsDataURL($event.target.files[0]);
      this.nomePlanilha = $event.target.files[0].name
    },
    chamaConfirmacao() {
      const config = {
        ativado: true,
        tipo: "confirmacao",
        mensagem: `Tem certeza que deseja enviar a planilha para processamento ?`,
        func: this.salvar
      }
      this.$store.commit("SET_MODAL_MENSAGEM", config);
    },
    salvar() {
      const data = new FormData()
      data.append("Planilha", this.planilha)
      data.append("IdGrupoCargo", this.idCargo.id)
      data.append("TipoApuracao", "RANKING")

      enviarPlanilhaRanking(data).then(() => {
        this.erros = false;
      }).catch(erro => {
        this.$store.commit("SET_MODAL", {
          ativado: true,
          tipo: "erro",
          mensagem: "Corrija os erros abaixo",
        });
        setTimeout(() => {
          this.$store.commit('SET_MODAL', {
            ativado: false,
            mensagem: '',
            tipo: '',
          });
        }, 2000)
        this.erros = true;
        this.tipoErros = erro
      })
    },
  },
  mounted() {
    listarCargosAgrupados().then((resp) => {
      this.cargos = resp.data;
    })
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss">
.select-cargo {
  max-width: 380px;
}
.formulario-promocoes {
  .btn-icon {
    display: flex;
    justify-content: space-evenly !important;
    align-items: center;
  }

  .form-planilha {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .planilha-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn-padding {
    padding: 10px 25px;
  }

  .margin-top {
    margin-top: 80px;
  }

  .nova-comunicacao-container {
    padding: 10px 0 50px 0;

    h2 {
      font-size: 25px;
      margin-bottom: 10px;
    }
  }
}

.media {
  width: 50% !important;
  height: 80px;
  display: flex;
  justify-content: center;

  label {
    width: 100px;
  }

  .checkbox {
    display: flex;
    flex-direction: column;
    margin-left: 17px;

    input {
      margin-top: 15px;
    }

    a {
      text-decoration: none;
    }
  }

  .planilha-btn {
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 5px 0 10px 0;
    cursor: pointer;
    transition: 0.3s;
    color: #fff;
    border-radius: 8px;
    background: #33b933;
    padding: 6px 20px;
  }
}
</style>
